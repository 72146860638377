/**
 * ReSass.
 *
 * MIXIN: Responsive Media Queries.
 *
 * Creates responsive media queries for seven different screen sizes.
 * These are based on min-width which means if x is the size then your
 * CSS will affect any device with screen width x and above.
 *
 * USAGE:
 *	@include r(240)  { }
 *	@include r(320)  { }
 *	@include r(480)  { }
 *	@include r(768)  { }
 *	@include r(1024) { }
 *	@include r(1140) { }
 *	@include r(1280) { }
 *  @include r(1500) { }
 *
 * CSS content goes inside {} brackets. These mixins should be used inside
 * a class definition. For example:
 *
 * The following CSS will hide the .header on screen width 320px and above.
 * .header {
 * 	  @include r(320)  { display: none; }
 * }
 *
 * @author  Ahmad Awais (https://github.com/ahmadawais)
 * @link    https://github.com/ahmadawais/ReSass.git
 * @version 1.0.0
 */
.container--projects {
  background-color: #ffffff;
  scroll-snap-align: start;
  min-height: 100vh;
  width: 100%;
  padding-top: 100vh; }
  .container--projects h1 {
    margin: 5rem 0; }
  .container--projects .button--wrapper {
    padding: 2rem 0 4rem; }

.cards {
  display: grid;
  grid-template-columns: auto;
  max-width: 1280px;
  margin: 3rem auto; }
  @media (min-width: 480px) {
    .cards {
      grid-template-columns: auto auto; } }
  @media (min-width: 768px) {
    .cards {
      grid-template-columns: auto auto auto; } }

.container--contact {
  background-color: #009688;
  color: #FFFFFF;
  height: 100vh;
  scroll-snap-align: start;
  box-shadow: 2px 0 9px rgba(0, 0, 0, 0.3); }
  .container--contact h1 {
    padding: 5rem 0;
    margin: 0; }

.contact-form {
  width: 80%;
  padding: 1rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column; }
  @media (min-width: 480px) {
    .contact-form {
      width: 60%; } }
  .contact-form .contact-button {
    margin: 1rem auto 0; }
    .contact-form .contact-button button {
      border-color: #FF5722;
      background-color: #FF5722;
      color: white; }
      .contact-form .contact-button button:hover {
        border-color: #F7B733;
        background-color: #F7B733;
        color: white; }
      .contact-form .contact-button button:disabled {
        cursor: not-allowed;
        border-color: #BDBDBD;
        background-color: #BDBDBD; }
  .contact-form p {
    display: flex;
    flex-direction: column;
    margin: .5rem 0;
    align-items: center;
    width: 100%; }
  .contact-form input, .contact-form textarea {
    border: none;
    font-size: 1.3rem;
    font-family: 'Josefin Sans', sans-serif;
    border: 2px solid #B2DFDB;
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 10px;
    padding: .5rem;
    width: 100%; }
    .contact-form input:focus, .contact-form input:hover, .contact-form textarea:focus, .contact-form textarea:hover {
      outline: none;
      border-color: #FFFFFF; }
    .contact-form input::placeholder, .contact-form textarea::placeholder {
      color: white; }
  .contact-form label {
    margin-bottom: .5rem;
    text-align: left; }
  .contact-form input {
    height: 2.5rem;
    line-height: 3rem; }
  .contact-form textarea {
    height: 7rem;
    line-height: 2rem; }
