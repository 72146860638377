body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/**
 * ReSass.
 *
 * MIXIN: Responsive Media Queries.
 *
 * Creates responsive media queries for seven different screen sizes.
 * These are based on min-width which means if x is the size then your
 * CSS will affect any device with screen width x and above.
 *
 * USAGE:
 *	@include r(240)  { }
 *	@include r(320)  { }
 *	@include r(480)  { }
 *	@include r(768)  { }
 *	@include r(1024) { }
 *	@include r(1140) { }
 *	@include r(1280) { }
 *  @include r(1500) { }
 *
 * CSS content goes inside {} brackets. These mixins should be used inside
 * a class definition. For example:
 *
 * The following CSS will hide the .header on screen width 320px and above.
 * .header {
 * 	  @include r(320)  { display: none; }
 * }
 *
 * @author  Ahmad Awais (https://github.com/ahmadawais)
 * @link    https://github.com/ahmadawais/ReSass.git
 * @version 1.0.0
 */
.header {
  background-color: #009688;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
  width: calc(100% + 12px);
  height: 100vh;
  scroll-snap-align: start;
  box-shadow: 2px 0 9px rgba(0, 0, 0, 0.3); }
  @media (min-width: 480px) {
    .header {
      -webkit-flex-direction: row;
              flex-direction: row; } }

@-webkit-keyframes pushLeft {
  0% {
    width: 100%; }
  70% {
    width: 100%; }
  100% {
    width: 40%; } }

@keyframes pushLeft {
  0% {
    width: 100%; }
  70% {
    width: 100%; }
  100% {
    width: 40%; } }

@-webkit-keyframes pushUp {
  0% {
    height: 100vh; }
  70% {
    height: 100vh; }
  100% {
    height: 15vh; } }

@keyframes pushUp {
  0% {
    height: 100vh; }
  70% {
    height: 100vh; }
  100% {
    height: 15vh; } }

@-webkit-keyframes greetingAppear {
  0% {
    opacity: 0; }
  25% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes greetingAppear {
  0% {
    opacity: 0; }
  25% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes cartoonAppear {
  0% {
    opacity: 0; }
  25% {
    opacity: 1; } }

@keyframes cartoonAppear {
  0% {
    opacity: 0; }
  25% {
    opacity: 1; } }

@-webkit-keyframes scrollZIndex {
  0% {
    z-index: -2; }
  95% {
    z-index: -2; }
  100% {
    z-index: 2; } }

@keyframes scrollZIndex {
  0% {
    z-index: -2; }
  95% {
    z-index: -2; }
  100% {
    z-index: 2; } }

.header--static {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 15vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #009688;
  width: 100%;
  -webkit-animation: 3s ease-in-out 0s 1 pushUp;
          animation: 3s ease-in-out 0s 1 pushUp; }
  @media (min-width: 768px) {
    .header--static {
      width: 40%;
      box-shadow: 2px 0 9px rgba(0, 0, 0, 0.3);
      -webkit-animation: 3s ease-in-out 0s 1 pushLeft;
              animation: 3s ease-in-out 0s 1 pushLeft;
      height: 100vh; } }

.profile-cartoon {
  height: auto;
  width: 15rem;
  padding-top: 4rem;
  background-color: #FF5722;
  border-radius: 50%;
  -webkit-transition: opacity 2s ease;
  transition: opacity 2s ease;
  opacity: 1; }

.greeting {
  -webkit-animation: 2s ease-out .5s 1 greetingAppear;
          animation: 2s ease-out .5s 1 greetingAppear;
  opacity: 0;
  margin-top: 1rem;
  font-size: 1.5rem;
  width: 100%; }

.header--scroll {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-scroll-snap-type: y mandatory;
      -ms-scroll-snap-type: y mandatory;
          scroll-snap-type: y mandatory;
  z-index: 2;
  -webkit-animation-name: scrollZIndex;
          animation-name: scrollZIndex;
  -webkit-animation-duration: 3s;
          animation-duration: 3s; }
  @media (min-width: 768px) {
    .header--scroll {
      text-align: left;
      width: 60%;
      padding-left: 40%; } }

.header--scroll-container {
  scroll-snap-align: start;
  height: 100vh;
  -webkit-align-self: center;
          align-self: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center; }
  .header--scroll-container p {
    font-size: 1.1rem;
    margin-left: 4%;
    width: 90%; }
  @media (min-width: 768px) {
    .header--scroll-container {
      padding-left: 2rem;
      margin-left: none; }
      .header--scroll-container p {
        margin-left: 0; } }

.header--story {
  background-color: white;
  color: black; }

.header--work {
  line-height: 2.5rem; }
  .header--work a {
    color: #FFFFFF; }
    .header--work a:hover {
      background-color: #F7B733; }

/**
 * ReSass.
 *
 * MIXIN: Responsive Media Queries.
 *
 * Creates responsive media queries for seven different screen sizes.
 * These are based on min-width which means if x is the size then your
 * CSS will affect any device with screen width x and above.
 *
 * USAGE:
 *	@include r(240)  { }
 *	@include r(320)  { }
 *	@include r(480)  { }
 *	@include r(768)  { }
 *	@include r(1024) { }
 *	@include r(1140) { }
 *	@include r(1280) { }
 *  @include r(1500) { }
 *
 * CSS content goes inside {} brackets. These mixins should be used inside
 * a class definition. For example:
 *
 * The following CSS will hide the .header on screen width 320px and above.
 * .header {
 * 	  @include r(320)  { display: none; }
 * }
 *
 * @author  Ahmad Awais (https://github.com/ahmadawais)
 * @link    https://github.com/ahmadawais/ReSass.git
 * @version 1.0.0
 */
#root {
  overflow: hidden; }

.App {
  overflow: hidden; }

.button {
  border: 2px solid #ffffff;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0);
  padding: 1rem;
  margin: 0;
  width: 9rem;
  -webkit-transition: background-color .5s ease;
  transition: background-color .5s ease;
  -webkit-transition: border .5s ease;
  transition: border .5s ease;
  text-decoration: none; }
  .button:hover {
    border-color: #F7B733;
    background-color: #F7B733;
    cursor: pointer; }
  .button:visited {
    color: #ffffff; }

.button--wrapper {
  margin-top: 2rem; }

.card {
  height: 15rem;
  position: relative;
  overflow: hidden; }
  .card img {
    width: 100%;
    height: 15rem;
    object-fit: cover;
    margin-left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .card:hover img {
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    opacity: 0;
    overflow: hidden;
    width: 120%; }
  .card:hover .card-overlay {
    background-color: #FFFFFF;
    color: black;
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out; }
  .card:hover .card-stack {
    color: #FC4A1A;
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out; }
  .card:hover .card-link {
    color: black;
    border-color: #FC4A1A;
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out; }
    .card:hover .card-link:hover {
      color: white;
      background-color: #FC4A1A;
      -webkit-transition: .25s ease-in-out;
      transition: .25s ease-in-out; }

.green {
  background-color: #009688; }

.yellow {
  background-color: #F7B733; }

.card-overlay {
  -webkit-transition: background-color 1s ease;
  transition: background-color 1s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 15rem;
  margin: auto;
  z-index: 1;
  color: rgba(255, 255, 255, 0);
  display: grid;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center; }

.card-title {
  margin: 0; }

.card-stack {
  margin-bottom: 3rem; }

.card-link {
  color: rgba(255, 255, 255, 0);
  border: 1px solid rgba(252, 74, 26, 0);
  padding: .7rem 1rem;
  text-decoration: none; }

/**
 * ReSass.
 *
 * MIXIN: Responsive Media Queries.
 *
 * Creates responsive media queries for seven different screen sizes.
 * These are based on min-width which means if x is the size then your
 * CSS will affect any device with screen width x and above.
 *
 * USAGE:
 *	@include r(240)  { }
 *	@include r(320)  { }
 *	@include r(480)  { }
 *	@include r(768)  { }
 *	@include r(1024) { }
 *	@include r(1140) { }
 *	@include r(1280) { }
 *  @include r(1500) { }
 *
 * CSS content goes inside {} brackets. These mixins should be used inside
 * a class definition. For example:
 *
 * The following CSS will hide the .header on screen width 320px and above.
 * .header {
 * 	  @include r(320)  { display: none; }
 * }
 *
 * @author  Ahmad Awais (https://github.com/ahmadawais)
 * @link    https://github.com/ahmadawais/ReSass.git
 * @version 1.0.0
 */
.container--projects {
  background-color: #ffffff;
  scroll-snap-align: start;
  min-height: 100vh;
  width: 100%;
  padding-top: 100vh; }
  .container--projects h1 {
    margin: 5rem 0; }
  .container--projects .button--wrapper {
    padding: 2rem 0 4rem; }

.cards {
  display: grid;
  grid-template-columns: auto;
  max-width: 1280px;
  margin: 3rem auto; }
  @media (min-width: 480px) {
    .cards {
      grid-template-columns: auto auto; } }
  @media (min-width: 768px) {
    .cards {
      grid-template-columns: auto auto auto; } }

.container--contact {
  background-color: #009688;
  color: #FFFFFF;
  height: 100vh;
  scroll-snap-align: start;
  box-shadow: 2px 0 9px rgba(0, 0, 0, 0.3); }
  .container--contact h1 {
    padding: 5rem 0;
    margin: 0; }

.contact-form {
  width: 80%;
  padding: 1rem;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media (min-width: 480px) {
    .contact-form {
      width: 60%; } }
  .contact-form .contact-button {
    margin: 1rem auto 0; }
    .contact-form .contact-button button {
      border-color: #FF5722;
      background-color: #FF5722;
      color: white; }
      .contact-form .contact-button button:hover {
        border-color: #F7B733;
        background-color: #F7B733;
        color: white; }
      .contact-form .contact-button button:disabled {
        cursor: not-allowed;
        border-color: #BDBDBD;
        background-color: #BDBDBD; }
  .contact-form p {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: .5rem 0;
    -webkit-align-items: center;
            align-items: center;
    width: 100%; }
  .contact-form input, .contact-form textarea {
    border: none;
    font-size: 1.3rem;
    font-family: 'Josefin Sans', sans-serif;
    border: 2px solid #B2DFDB;
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 10px;
    padding: .5rem;
    width: 100%; }
    .contact-form input:focus, .contact-form input:hover, .contact-form textarea:focus, .contact-form textarea:hover {
      outline: none;
      border-color: #FFFFFF; }
    .contact-form input::-webkit-input-placeholder, .contact-form textarea::-webkit-input-placeholder {
      color: white; }
    .contact-form input::-ms-input-placeholder, .contact-form textarea::-ms-input-placeholder {
      color: white; }
    .contact-form input::placeholder, .contact-form textarea::placeholder {
      color: white; }
  .contact-form label {
    margin-bottom: .5rem;
    text-align: left; }
  .contact-form input {
    height: 2.5rem;
    line-height: 3rem; }
  .contact-form textarea {
    height: 7rem;
    line-height: 2rem; }

.App {
  text-align: center;
  position: relative;
  font-family: 'Josefin Sans', sans-serif;
  -webkit-scroll-snap-type: y mandatory;
      -ms-scroll-snap-type: y mandatory;
          scroll-snap-type: y mandatory;
  overflow-y: scroll; }
  .App .snap {
    scroll-snap-align: start; }

