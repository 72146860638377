
.App {
  text-align: center;
  position: relative;
  font-family: 'Josefin Sans', sans-serif;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;

  .snap {
      scroll-snap-align: start;
  }
}
