/**
 * ReSass.
 *
 * MIXIN: Responsive Media Queries.
 *
 * Creates responsive media queries for seven different screen sizes.
 * These are based on min-width which means if x is the size then your
 * CSS will affect any device with screen width x and above.
 *
 * USAGE:
 *	@include r(240)  { }
 *	@include r(320)  { }
 *	@include r(480)  { }
 *	@include r(768)  { }
 *	@include r(1024) { }
 *	@include r(1140) { }
 *	@include r(1280) { }
 *  @include r(1500) { }
 *
 * CSS content goes inside {} brackets. These mixins should be used inside
 * a class definition. For example:
 *
 * The following CSS will hide the .header on screen width 320px and above.
 * .header {
 * 	  @include r(320)  { display: none; }
 * }
 *
 * @author  Ahmad Awais (https://github.com/ahmadawais)
 * @link    https://github.com/ahmadawais/ReSass.git
 * @version 1.0.0
 */
.header {
  background-color: #009688;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: calc(100% + 12px);
  height: 100vh;
  scroll-snap-align: start;
  box-shadow: 2px 0 9px rgba(0, 0, 0, 0.3); }
  @media (min-width: 480px) {
    .header {
      flex-direction: row; } }

@keyframes pushLeft {
  0% {
    width: 100%; }
  70% {
    width: 100%; }
  100% {
    width: 40%; } }

@keyframes pushUp {
  0% {
    height: 100vh; }
  70% {
    height: 100vh; }
  100% {
    height: 15vh; } }

@keyframes greetingAppear {
  0% {
    opacity: 0; }
  25% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes cartoonAppear {
  0% {
    opacity: 0; }
  25% {
    opacity: 1; } }

@keyframes scrollZIndex {
  0% {
    z-index: -2; }
  95% {
    z-index: -2; }
  100% {
    z-index: 2; } }

.header--static {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 15vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #009688;
  width: 100%;
  animation: 3s ease-in-out 0s 1 pushUp; }
  @media (min-width: 768px) {
    .header--static {
      width: 40%;
      box-shadow: 2px 0 9px rgba(0, 0, 0, 0.3);
      animation: 3s ease-in-out 0s 1 pushLeft;
      height: 100vh; } }

.profile-cartoon {
  height: auto;
  width: 15rem;
  padding-top: 4rem;
  background-color: #FF5722;
  border-radius: 50%;
  transition: opacity 2s ease;
  opacity: 1; }

.greeting {
  animation: 2s ease-out .5s 1 greetingAppear;
  opacity: 0;
  margin-top: 1rem;
  font-size: 1.5rem;
  width: 100%; }

.header--scroll {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  z-index: 2;
  animation-name: scrollZIndex;
  animation-duration: 3s; }
  @media (min-width: 768px) {
    .header--scroll {
      text-align: left;
      width: 60%;
      padding-left: 40%; } }

.header--scroll-container {
  scroll-snap-align: start;
  height: 100vh;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .header--scroll-container p {
    font-size: 1.1rem;
    margin-left: 4%;
    width: 90%; }
  @media (min-width: 768px) {
    .header--scroll-container {
      padding-left: 2rem;
      margin-left: none; }
      .header--scroll-container p {
        margin-left: 0; } }

.header--story {
  background-color: white;
  color: black; }

.header--work {
  line-height: 2.5rem; }
  .header--work a {
    color: #FFFFFF; }
    .header--work a:hover {
      background-color: #F7B733; }
