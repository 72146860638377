/**
 * ReSass.
 *
 * MIXIN: Responsive Media Queries.
 *
 * Creates responsive media queries for seven different screen sizes.
 * These are based on min-width which means if x is the size then your
 * CSS will affect any device with screen width x and above.
 *
 * USAGE:
 *	@include r(240)  { }
 *	@include r(320)  { }
 *	@include r(480)  { }
 *	@include r(768)  { }
 *	@include r(1024) { }
 *	@include r(1140) { }
 *	@include r(1280) { }
 *  @include r(1500) { }
 *
 * CSS content goes inside {} brackets. These mixins should be used inside
 * a class definition. For example:
 *
 * The following CSS will hide the .header on screen width 320px and above.
 * .header {
 * 	  @include r(320)  { display: none; }
 * }
 *
 * @author  Ahmad Awais (https://github.com/ahmadawais)
 * @link    https://github.com/ahmadawais/ReSass.git
 * @version 1.0.0
 */
#root {
  overflow: hidden; }

.App {
  overflow: hidden; }

.button {
  border: 2px solid #ffffff;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0);
  padding: 1rem;
  margin: 0;
  width: 9rem;
  transition: background-color .5s ease;
  transition: border .5s ease;
  text-decoration: none; }
  .button:hover {
    border-color: #F7B733;
    background-color: #F7B733;
    cursor: pointer; }
  .button:visited {
    color: #ffffff; }

.button--wrapper {
  margin-top: 2rem; }
