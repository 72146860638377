.card {
    height: 15rem;
    position: relative;
    overflow: hidden;

    img {
        width: 100%;
        height: 15rem;
        object-fit: cover;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    &:hover {
        img {
          transition: .5s ease-in-out;
          opacity: 0;
          overflow: hidden;
          width: 120%;
        }

        .card-overlay {
            background-color: #FFFFFF;
            color: rgba(0, 0, 0, 1);
            transition: .5s ease-in-out;
        }

        .card-stack {
            color: #FC4A1A;
            transition: .5s ease-in-out;
        }

        .card-link {
            color: black;
            border-color: #FC4A1A;
            transition: .5s ease-in-out;

            &:hover {
                color: white;
                background-color: #FC4A1A;
                transition: .25s ease-in-out;
            }
        }

    }

}

.green {
    background-color: #009688;
}

.yellow {
    background-color: #F7B733;
}

.card-overlay {
    transition: background-color 1s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 15rem;
    margin: auto;
    z-index: 1;
    color: rgba(255, 255, 255, 0);
    display: grid;
    align-content: center;
    justify-content: center;

}

.card-title {
    margin: 0;
}

.card-stack {
    margin-bottom: 3rem;
}

.card-link {
    color: rgba(255, 255, 255, 0);
    border: 1px solid rgba(252, 74, 26, 0);
    padding: .7rem 1rem;
    text-decoration: none;
}
