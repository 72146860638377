/**
 * ReSass.
 *
 * MIXIN: Responsive Media Queries.
 *
 * Creates responsive media queries for seven different screen sizes.
 * These are based on min-width which means if x is the size then your
 * CSS will affect any device with screen width x and above.
 *
 * USAGE:
 *	@include r(240)  { }
 *	@include r(320)  { }
 *	@include r(480)  { }
 *	@include r(768)  { }
 *	@include r(1024) { }
 *	@include r(1140) { }
 *	@include r(1280) { }
 *  @include r(1500) { }
 *
 * CSS content goes inside {} brackets. These mixins should be used inside
 * a class definition. For example:
 *
 * The following CSS will hide the .header on screen width 320px and above.
 * .header {
 * 	  @include r(320)  { display: none; }
 * }
 *
 * @author  Ahmad Awais (https://github.com/ahmadawais)
 * @link    https://github.com/ahmadawais/ReSass.git
 * @version 1.0.0
 */
@mixin r( $point ) {
	@if $point==240 {
		@media ( min-width: 240px ) {
			@content;
		}
	}
	@if $point==320 {
		@media ( min-width: 320px ) {
			@content;
		}
	}
	@if $point==480 {
		@media ( min-width: 480px ) {
			@content;
		}
	}
	@if $point==600 {
		@media ( min-width: 600px ) {
			@content;
		}
	}
	@if $point==768 {
		@media ( min-width: 768px ) {
			@content;
		}
	}
	@if $point==1024 {
		@media ( min-width: 1024px ) {
			@content;
		}
	}
	@if $point==1140 {
		@media ( min-width: 1140px ) {
			@content;
		}
	}
	@if $point==1280 {
		@media ( min-width: 1280px ) {
			@content;
		}
	}
	@if $point==1500 {
		@media ( min-width: 1500px ) {
			@content;
		}
	}
}
